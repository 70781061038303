body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.react-datepicker__time-list {
  padding-left: 0px;
}
.react-datepicker--time-only .react-datepicker__triangle {
  left: 50px;
}
.react-datepicker__time-container,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 100px;
}
